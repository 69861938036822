<template>
    <div>
        <!-- <div id="load_screen"> <div class="loader"> <div class="loader-content">
        <div class="spinner-grow align-self-center"></div>
        </div></div></div> -->

        <!--  BEGIN NAVBAR  -->
        <div class="header-container fixed-top" style="border-bottom: 0">
            <header class="header navbar navbar-expand-sm" style="background: white;">

                <ul class="navbar-item theme-brand flex-row  text-center">
                    <li class="nav-item">
                        <a href="/">
                            <img src="../assets/new-logo.png" style="height: 70px" class="" alt="FTD Logistics">
                        </a>
                    </li>
                    <!-- <li class="nav-item theme-text">
                        <a href="/" class="nav-link">FTD</a>
                    </li> -->
                </ul>

                <!-- <ul class="navbar-item flex-row ml-md-0 ml-auto">
                    <li class="nav-item align-self-center search-animated">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search toggle-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                        <form class="form-inline search-full form-inline search" role="search">
                            <div class="search-bar">
                                <input type="text" class="form-control search-form-control  ml-lg-auto" placeholder="Search...">
                            </div>
                        </form>
                    </li>
                </ul> -->

                <ul class="navbar-item flex-row ml-md-auto">
                    <!-- <li class="nav-item dropdown notification-dropdown">
                        <a href="javascript:void(0);" class="nav-link dropdown-toggle" id="notificationDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg><span class="badge badge-success"></span>
                        </a>
                        <div class="dropdown-menu position-absolute" aria-labelledby="notificationDropdown">
                            <div class="notification-scroll">

                                <div class="dropdown-item">
                                    <div class="media">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
                                        <div class="media-body">
                                            <div class="notification-para"><span class="user-name">Shaun Park</span> likes your photo.</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="dropdown-item">
                                    <div class="media">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-share-2"><circle cx="18" cy="5" r="3"></circle><circle cx="6" cy="12" r="3"></circle><circle cx="18" cy="19" r="3"></circle><line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line><line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line></svg>
                                        <div class="media-body">
                                            <div class="notification-para"><span class="user-name">Kelly Young</span> shared your post</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="dropdown-item">
                                    <div class="media">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-tag"><path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path><line x1="7" y1="7" x2="7" y2="7"></line></svg>
                                        <div class="media-body">
                                            <div class="notification-para"><span class="user-name">Kelly Young</span> mentioned you in comment.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li> -->
                    <li class="nav-item">
                        <a href="javascript:void(0);" class="sidebarCollapse nav-link" data-placement="bottom" style="line-height: 2rem; font-size: 1.5rem;">
                            <i class="fa fa-bars"></i>
                        </a>
                    </li>
                    <li class="nav-item dropdown user-profile-dropdown">
                        <a href="javascript:void(0);" class="nav-link dropdown-toggle user" id="userProfileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                            <span class="">{{ auth.user().first_name }}</span>
                        </a>
                        <div class="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown">
                            <div class="">
                                <div class="dropdown-item">
                                    <router-link class="" to="/account"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> My Profile</router-link>
                                </div>
                                <!-- <div class="dropdown-item">
                                    <a class="" href="apps_mailbox.html"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-inbox"><polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline><path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path></svg> Inbox</a>
                                </div>
                                <div class="dropdown-item">
                                    <a class="" href="auth_lockscreen.html"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg> Lock Screen</a>
                                </div> -->
                                <div class="dropdown-item">
                                    <router-link class="" to="/logout"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg> Sign Out</router-link>
                                </div>
                            </div>
                        </div>
                    </li>

                </ul>
            </header>
        </div>
        <!--  END NAVBAR  -->
        <!--  BEGIN MAIN CONTAINER  -->
        <div class="main-container" id="container">

            <div class="overlay"></div>
            <div class="search-overlay"></div>

            <!--  BEGIN SIDEBAR  -->
            <div class="sidebar-wrapper sidebar-theme">
                
                <nav id="sidebar">
                    <!-- <div class="shadow-bottom"></div> -->
                    <ul class="list-unstyled menu-categories" id="accordionExample">
                        <!-- <li class="menu">
                            <a href="#orders" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                                <div>
                                    <i class="fa fa-list"></i>
                                    <span> Orders</span>
                                </div>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                                </div>
                            </a>
                            <ul class="collapse submenu list-unstyled" id="orders" data-parent="#accordionExample">
                                <li>
                                    <router-link to="/create-order">Place Order</router-link>
                                </li>
                                <li>
                                    <router-link to="/orders">List Orders</router-link>
                                </li>
                            </ul>
                        </li> -->
                        <li class="menu">
                            <router-link to="/create-order" class="dropdown-toggle" :data-active="isCurrentRoute('/create-order')">
                                <div><i class="fa fa-truck-pickup fa-fw"></i> <span>Place Order</span></div>
                            </router-link>
                        </li>
                        <li class="menu">
                            <router-link class="dropdown-toggle" to="/bulk-order" :data-active="isCurrentRoute('/bulk-order')">
                                <div><i class="fa fa-upload fa-fw"></i> <span>Upload bulk order</span></div>
                            </router-link>
                        </li>
                        <li class="menu">
                            <router-link to="/orders" class="dropdown-toggle" :data-active="isCurrentRoute('/orders')">
                                <div><i class="fa fa-list fa-fw"></i> <span> My Orders</span></div>
                            </router-link>
                        </li>
                        <li class="menu">
                            <router-link class="dropdown-toggle" to="/wallet" :data-active="isCurrentRoute('/wallet')">
                                <div>
                                    <i class="fa fa-wallet"></i> <span>Wallet</span>
                                </div>
                            </router-link>
                        </li>
                        <li class="menu">
                            <router-link class="dropdown-toggle" to="/packages" :data-active="isCurrentRoute('/packages')">
                                <div>
                                    <i class="fa fa-ticket-alt fa-fw"></i> <span>Subscriptions</span>
                                </div>
                            </router-link>
                        </li>
                        <li class="menu">
                            <router-link to="/account" class="dropdown-toggle" :data-active="isCurrentRoute('/account')">
                                <div>
                                    <i class="fa fa-cog fa-fw"></i> <span>Account</span>
                                </div>
                            </router-link>
                        </li>
                        <li class="menu">
                            <router-link class="dropdown-toggle" to="/logout">
                                <div><i class="fa fa-sign-out-alt fa-fw"></i> <span>Logout</span></div>
                            </router-link>
                        </li>
                        
                        <!-- <li class="menu">
                            <router-link to="/orders" aria-expanded="false" class="dropdown-toggle">
                                <div class="">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layout"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="3" y1="9" x2="21" y2="9"></line><line x1="9" y1="21" x2="9" y2="9"></line></svg>
                                    <span>Orders</span>
                                </div>
                            </router-link>
                        </li> -->
                    </ul>
                    <!-- <div class="shadow-bottom"></div> -->
                    
                </nav>

            </div>
            <!--  END SIDEBAR  -->
            
            <!--  BEGIN CONTENT AREA  -->
            <div id="content" class="main-content" style="margin-top:70px">
                <div class="layout-px-spacing">
                    <div class="layout-top-spacing">
                        <slot></slot>
                    </div>
                </div>
                <div class="footer-wrapper">
                    <div class="footer-section f-section-1">
                        <p class="">Copyright © {{ new Date().getFullYear() }} <a href="javascript:void(0)">FTDLogistics</a>, All rights reserved.</p>.
                    </div>
                </div>
            </div>
            <!--  END CONTENT AREA  -->

        </div>
        <!-- END MAIN CONTAINER -->
    </div>
</template>
<style scoped>
@import url("../assets/lib/vendor/css/dashboard/dash_1.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");
</style>
<script>
import appconfig from '../appconfig';
import Auth from '../helpers/auth';
export default {
    data(){
        return {
            auth: Auth
        }
    },
    mounted(){
        $(document).ready(function() {
            App.init();
            // setTimeout(() => {
            //     var load_screen = window.document.getElementById("load_screen");
            //     load_screen.style="display:none";
            // }, 0);
            
        });
    },
    methods: {
        isCurrentRoute(route){
            let currentRoutePath = this.$route.path;
            if (route == currentRoutePath){
                return true;
            }
            return false;
        }
    }
}
</script>
<style scoped>
#sidebar *{
    overflow: initial;
    /* font-size: initial; */
}
</style>